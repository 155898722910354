import { Close } from "@mui/icons-material"
import { Box, Button, Modal, Stack, TextField, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { tandctext } from "../../../Content/tandc"
import { RequiredPrompts } from "../Components/required.prompts"
import { useLoadingIndicator } from "../../../Hooks/misc";
interface SubmitPageProps {
    setNotes: (n: string) => void,
    handleSubmit: ()=>void
}

export const SubmitPage: React.FC<SubmitPageProps> = ({ setNotes, handleSubmit }: SubmitPageProps) => {

    const renderModal = (
        open: boolean,
        handleClose: (b: boolean) => void
    ) => {
        return (
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        maxHeight: '400px',
                        overflowY: 'auto',
                        p: 4,
                    }}
                >
                    <Stack direction={"row"} sx={{ width: "100%", position: 'sticky' }} justifyContent={"space-between"}>
                        <Typography variant="h2">
                            Data Deposit Terms and Conditions
                        </Typography>
                        <Button aria-label="close" onClick={() => handleClose(false)}>
                            <Close />
                        </Button>
                    </Stack>
                    {tandctext.map((item, index) => {
                        if (typeof item === 'string') {
                            return <Typography variant="body2" key={index}>{item}</Typography>;
                        } else {
                            return (
                                <Box key={index} sx={{ marginLeft: '20px' }}>
                                    {item.map((subItem, subIndex) => (
                                        <Typography variant="body2" key={subIndex}>{subItem}</Typography>
                                    ))}
                                </Box>
                            );
                        }
                    })}
                </Box>
            </Modal>
        );
    };
    const [notesL, setNotesL] = useState('')
    const [hasSeenTerms, setSeenTerms] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(()=>{if(modalOpen)setSeenTerms(true)},[modalOpen])
    useEffect(() => {setNotes(notesL)}, [notesL, setNotes])
    return useLoadingIndicator() ||
      <Box paddingLeft={"50px"} paddingRight={"50px"} marginTop="20px">
        {renderModal(modalOpen, setModalOpen)}
        <Stack direction="column" sx={{ width: "100%" }} spacing={1}>
            <Typography variant="h1" gutterBottom>
                Accept Terms and Submit
            </Typography>
            <Typography variant="largeParagraph">
                Enter any remaining comments about this submission and read and accept our terms and
                conditions for this submission.
            </Typography>
            <RequiredPrompts req={false} minReq={false} header="Is there any additional information you would like the Roper Center to know about this deposit?"></RequiredPrompts>
            <TextField label={"Additional Information"} multiline minRows={4} onChange={(e) => setNotesL(e.target.value)} value={notesL}></TextField>
            <RequiredPrompts req={true} minReq={false} header="Roper Center Data Deposit Agreement"></RequiredPrompts>
            <Typography variant="body2" >
                When you are ready to submit your files to the Roper Center, please read the Terms and Conditions by clicking the button below, then attest that you read and agree to the terms. After submitting, you will not be able to revise this page.
            </Typography>
            <Button sx={{width:'300px'}} variant="outlined" onClick={()=>{setModalOpen(true)}}>Terms and Conditions</Button>
            {hasSeenTerms && <Typography>By clicking submit, I attest that the information provided in this deposit form is accurate and I agree to the terms stated above.</Typography>}
            {hasSeenTerms && <Button aria-label="submit" onClick={handleSubmit} variant="contained" fullWidth sx={{ marginTop: "10px" }}>Submit</Button>}
        </Stack>


    </Box>
}
