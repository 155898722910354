import { Autocomplete, CircularProgress, Stack, TextField, Typography } from "@mui/material"
import { AxiosResponse } from "axios"
import { debounce } from "lodash"
import { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form"
import { GISEntity } from "../../../Types/DAO/GISEntity"
import { useHandleHttpError } from "../../../Hooks/misc";

export type WrappedGISEntityResponse = {
    data: GISEntity[]
}

interface GISAutocompleteProps {
    cID: string | null,
    sID: string | null,
    level: "Country" | "State" | "City"
    fetcher:
    ((q: string) => Promise<AxiosResponse<WrappedGISEntityResponse | GISEntity[]>>) |
    ((q: string, c: string) => Promise<AxiosResponse<WrappedGISEntityResponse | GISEntity[]>>) |
    ((q: string, c: string, s: string) => Promise<AxiosResponse<WrappedGISEntityResponse | GISEntity[]>>) |
    null,
    disabled: boolean,
    name: string
}

export const GISAutocomplete: React.FC<GISAutocompleteProps> = ({
    cID,
    sID,
    level,
    fetcher,
    disabled = false,
    name
}) => {


    const handleHttpError = useHandleHttpError();
    const getOptionsDelayed = useMemo(() => debounce(
        (query: string, callback: (options: AxiosResponse<WrappedGISEntityResponse | GISEntity[]>) => void) => {
            if (query.length >= 3) {
                setAutocompleteOptions([])
                setFetching(true)
                /**
                 * Type cast and call fetcher function based on level.
                 */
                switch (level) {
                    case "Country":
                        (fetcher as ((q: string) => Promise<AxiosResponse<WrappedGISEntityResponse>>))(query)
                          .then((r) => callback(r)).catch(handleHttpError)
                        break
                    case "State":
                        (fetcher as ((q: string, c: string) => Promise<AxiosResponse<GISEntity[]>>))(query, cID!)
                          .then((r) => callback(r)).catch(handleHttpError)
                        break
                    case "City":
                        (fetcher as ((q: string, c: string, s: string) => Promise<AxiosResponse<GISEntity[]>>))(query, cID!, sID!)
                          .then((r) => callback(r)).catch(handleHttpError)
                }
            }
        },
        200
    ),[cID, handleHttpError, fetcher, level, sID]);

    const [query, setQuery] = useState("")
    const [fetching, setFetching] = useState(false)
    const [autocompleteOptions, setAutocompleteOptions] = useState<GISEntity[]>([])

    useEffect(() => {
        if (query !== "") {
            getOptionsDelayed(query, (options: AxiosResponse<WrappedGISEntityResponse | GISEntity[]>) => {
                if (Array.isArray(options.data)) {
                    setAutocompleteOptions((options.data as GISEntity[]));
                } else {
                    setAutocompleteOptions((options.data as WrappedGISEntityResponse).data)
                }
                setFetching(false)
            });
        }
    }, [query, getOptionsDelayed]);
    return (
        <Stack direction={"row"} alignItems="center" justifyContent="space-between" sx={{ width: "80%" }} spacing={2}>
            <Controller
                name={name}
                render={({ field, fieldState }) => {
                    return <Autocomplete
                        {...field}
                        onChange = {(_, val) => field.onChange(val)}
                        disabled={disabled}
                        fullWidth
                        options={autocompleteOptions}
                        noOptionsText={
                            <Typography variant="subtitle2">No results found</Typography>
                        }
                        getOptionLabel={(e: GISEntity) => e.name}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                            <TextField
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                sx={{
                                    fontSize: "15px",
                                    "& .MuiFormLabel-root": {
                                        fontSize: "14px",
                                    },
                                }}
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <div>
                                            {fetching ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </div>
                                    ),
                                }}
                                label={level}
                            />
                        )}
                    />

                }}
            />
        </Stack>
    );




}