import { CorporateFare, FolderOpen, School } from "@mui/icons-material";
import { Button, Card, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { DataGrid, GridToolbar, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useRetrieveStudiesForOrg, retrieveStudyFiles } from "../../../Client/Study/study.client";
import { ArchiveFile, Study } from "../../../Client/Study/types";
import { SERVICE_ID, UI_CONSTANTS } from "../../../Constants/ui.constants";
import { useOrg } from "../../../Hooks/Auth/useOrg";
import { renderErrorOverlay } from "../../Submissions/Pages/submissions.page";
import { Download } from "@mui/icons-material";
import { findEndpointById } from "../../../Client/Endpoints/endpoints";
import axios from "axios";
import { useHandleHttpError } from "../../../Hooks/misc";
interface SurveyPageProps {
    // none!
}

export const SurveyPage: React.FC<SurveyPageProps> = () => {
    const org = useOrg()!
    const { isPending, isError, data } = useRetrieveStudiesForOrg(org.id)
    const [open, setOpen] = useState(false);
    const [selectedStudyId, setSelectedStudyId] = useState('');
    const [fileData, setFileData] = useState<ArchiveFile[]>([]);
    const [loading, setLoading] = useState(true);
    const [studiesCoalesced, setStudiesCoalesced] = useState<Study[]>([]);
    const handleHttpError = useHandleHttpError()

    const handleDownload = async (id: string, epid: string) => {
        const endpoint = findEndpointById(epid)!;
        var epURL = new URL(endpoint);
        epURL.searchParams.append('id', id);

        const url = epURL.toString();
        try {
            const response = await axios.get(url, {
                withCredentials: true,
                headers: {
                    S: SERVICE_ID
                },
                responseType: 'blob', // Important to get the response as a blob
            });

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'downloaded-file';
            if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }
            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = URL.createObjectURL(new Blob([response.data]));
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'start',
        color: theme.palette.text.primary,
    }));


    const handleOpen = (id: string) => {
        setOpen(true);
        setSelectedStudyId(id);
        retrieveStudyFiles(id).then((data) => {
            setFileData(data.data.data);
            setLoading(false);
        }).catch(handleHttpError)
    };

    const studyCols: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 1 },
        { field: "archiveID", headerName: "Archive Number", flex: 0.5 },
        { field: "title", headerName: "Title", flex: 1 },
        {
            field: "startDate", headerName: "Start Date", type: 'date', flex: 0.5,
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        },
        {
            field: "endDate", headerName: "End Date", type: 'date', flex: 0.5,
            valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        },
        { field: "sponsors", headerName: "Sponsors", flex: 0.5 },
        { field: "surveyOrganizations", headerName: "Survey Organizations", flex: 0.5 },
        { field: "universe", headerName: "Universe", flex: 0.5 },
        { field: "sampleSize", headerName: "Sample Size", type: 'number', flex: 0.5 },
        {
            field: "datasetPublishedDate", headerName: "Date Published", type: 'date', flex: 0.5,
            valueFormatter: (params) => params.value && new Date(params.value).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
        },
        {
            field: 'actions',
            headerName: 'Downloads',
            align: 'center',
            headerAlign: 'center',
            width: 150,
            renderCell: (params) => (
                <Tooltip title="View Files">
                    <IconButton aria-label="view files" onClick={() => handleOpen(params.row.id)}>
                        <FolderOpen fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            ),
        },
    ]

    const fileCols: GridColDef[] = [
        { field: "id", headerName: "ID", flex: 1 },
        { field: "fileName", headerName: "File Name", flex: 1 },
        { field: "fileSize", headerName: "File Size", type: 'number', flex: 0.5 },
        { field: "checksum", headerName: "MD5 Checksum", flex: 0.6 },
        {
            field: 'actions',
            headerName: 'Download',
            align: 'center',
            headerAlign: 'center',
            width: 100,
            renderCell: (params) => (
                <Tooltip title="Download File">
                    <IconButton aria-label="download file" onClick={() => handleDownload(params.row.id, 'downloadfile')}>
                        <Download fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            ),
        },
    ]

    const fileDialog = <Dialog fullWidth maxWidth='lg'
        open={open} onClose={() => setOpen(false)} >
        <DialogTitle>Files</DialogTitle>
        <DialogContent>
            <DataGrid
                sx={{
                    boxShadow: 2,
                    border: 1,
                }}
                slots={{
                    noRowsOverlay: renderErrorOverlay,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                autoHeight
                disableRowSelectionOnClick
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },

                    },
                }}
                pageSizeOptions={[10, 25, 50]} rows={fileData} columns={fileCols} />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleDownload(selectedStudyId, 'allfiles')} startIcon={<Download />}>Download All Files</Button>
            <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
    </Dialog>;

    useEffect(() => {
        if (data) {
            const orgInfo = data.data.data;
            setStudiesCoalesced(orgInfo.studies.map((s: Study) => {
                return {
                    ...s,
                    startDate: s.startDate ? new Date(s.startDate) : null,
                    endDate: s.endDate ? new Date(s.endDate) : null,
                    datasetPublishedDate: s.datasetPublishedDate ? new Date(s.datasetPublishedDate) : null,
                }
            }))
            setLoading(false)
        }
    }, [data])

    return <Container component="main">
        <Stack direction="column" alignItems={"flex-start"} spacing={2}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                <CorporateFare color="primary" style={{fontSize: "3rem", paddingRight: '1rem'}}></CorporateFare>
                <Typography color={"primary"} variant="h1">
                    Organization Names
                </Typography>
            </Stack>

            <Card elevation={0} sx={{ width: "99%", padding: '20px', border: `2 px solid ${UI_CONSTANTS["Carnellian"]}` }}>
                {isPending && <Skeleton sx={{ width: "99%" }}></Skeleton>}
                {!isPending && data && !isError && org &&
                    <Stack spacing={1}>
                        {[...org.aliases].sort(function(a, b) {
                            if (a.name < b.name) {
                                return -1;
                            }
                            if (a.name > b.name) {
                                return 1;
                            }
                            return 0;
                        }).map((alias, index) => {
                            return <Item key={index}>
                                {alias.name.toLowerCase() === org.primaryName.toLowerCase() ? <Stack direction={"row"} spacing={2}>
                                    <Typography variant="orgLabel">{alias.name}</Typography>
                                    <Chip label="Current"/>
                                </Stack> : <Typography variant="orgLabel">{alias.name}</Typography>}
                            </Item>
                        })}
                    </Stack>
                }
            </Card>

            <Stack direction="row" alignItems={"center"} spacing={1}>
                <School color="primary" style={{fontSize: "3rem", paddingRight: '1rem'}}/>
                <Typography color="primary" variant="h1">Surveys</Typography>
            </Stack>

            <Card elevation={0} sx={{ width: "99%", padding: "20px", border: `2 px solid ${UI_CONSTANTS["Carnellian"]}` }}>
                <DataGrid
                    sx={{
                        boxShadow: 2,

                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        },
                    }}
                    slots={{
                        noRowsOverlay: renderErrorOverlay,
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            printOptions: { disableToolbarButton: true }
                        },
                    }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },

                        },
                    }}
                    autoHeight
                    loading={loading}
                    pageSizeOptions={[10, 25, 50]} rows={studiesCoalesced} columns={studyCols} />
            </Card>
        </Stack>
        {fileDialog}
    </Container>
}