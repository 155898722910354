import { Box, Button, Paper } from "@mui/material";
import { ErrorOverlay } from "../Misc/ErrorOverlay";
import { ROOT_URL } from "../../Constants/ui.constants";
export const ErrorPage: React.FC = () => {
    return <Box>
        <Paper sx={{ width: "90%", margin: "0 auto", marginTop: "50px", padding: '25px' }}>
            <ErrorOverlay message="A network error occurred, check your connectivity, or please contact the Roper Center for assistance.">
                <Button variant={"contained"} href={ROOT_URL}>Try Again</Button>
            </ErrorOverlay>
        </Paper>
    </Box >

}