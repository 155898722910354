import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import appStateReducer from './Reducer/app.state.reducer';
import networkStateReducer from './Reducer/network.state.reducer';

//
// Note the application uses Redux for global state management involving network status and
// authentication;  it probably could have been used to manage the state of all the forms
// with createEntityAdapter and some repetitive code which probably could have been wrapped
// to manage the connection between react-hook-form
//

const appPersistConfig = {
  key: 'appState',
  storage: storage,
};

const persistedAppStateReducer = persistReducer(appPersistConfig, appStateReducer);
export const rootReducer = combineReducers({
  appState: persistedAppStateReducer,
  networkState: networkStateReducer,
});

// notably,  the store is used for "global" properties of the app including keeping track of if the user is
// logged in or if the connection to the server is up but is not used for most application data such as the
// contents of the form that the user is filling out

export const store = configureStore({
  reducer: rootReducer,
});

// doesn't seem to ever get called,  but is used in a type definition below,  note replacing this
// with configureStore doesn't work,  probably because this function specifies a type parameter

function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  })
}
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch;
