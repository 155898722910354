import axios, { AxiosResponse } from 'axios';
import { findEndpointById } from '../Endpoints/endpoints';
import { SERVICE_ID } from '../../Constants/ui.constants';

// A real API call on the back end,  this never actually gets called but maybe it should when the
// user navigates from one page to another,  should probably get wrapped in use

export async function verifyStatus(
): Promise<AxiosResponse<any>> {
    const endpoint = findEndpointById("status")!;
    var url = new URL(endpoint);
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}






