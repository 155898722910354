import { PropsWithChildren } from "react";
import { Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export const FieldDescription = ({ children, sx }: PropsWithChildren<{sx?: SxProps<Theme>}>) => {
  return  <Typography variant="fieldDescription" sx={sx} gutterBottom>
    {children}
  </Typography>;
}
