import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { Nullable } from 'typescript-nullable';
import { SurveyData, WrappedSurveyData } from '../../Types/SurveyData/survey.type';
import { findEndpointById } from '../Endpoints/endpoints';
import { OrgStudyInfoResponse, SubmissionInfoWrapped, ArchiveFileWrapped } from './types';
import { SERVICE_ID } from '../../Constants/ui.constants';
import { useHandleHttpError } from "../../Hooks/misc";
const POST_OPTIONS = {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        S: SERVICE_ID
    }
};
export async function saveStudy(
    studyData: SurveyData,
    id: Nullable<string>
): Promise<AxiosResponse<WrappedSurveyData>> {
    if (!id) { console.error("Received null id."); return Promise.reject("Received null ID.") }
    const endpoint = findEndpointById('save')!
    return await axios.post(
        endpoint,
        JSON.stringify({
            ...studyData,
            ...studyData.addInfo,
            ...studyData.detailedInfo,
            uid: id!
        }),
        POST_OPTIONS
    )
}

export async function retrieveStudyFiles(
  sid: string
): Promise<AxiosResponse<ArchiveFileWrapped>> {
    const endpoint = findEndpointById("depositorfiles")!;
    var url = new URL(endpoint);
    url.searchParams.append('sid', sid)
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}


async function retrieveStudies(
    uid: string
): Promise<AxiosResponse<SubmissionInfoWrapped>> {
    const endpoint = findEndpointById("study")!;
    var url = new URL(endpoint);
    url.searchParams.append('uid', uid)
    return await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}

export const useRetrieveStudies = (uid: string) => {
    const handleHttpError = useHandleHttpError()
    return useQuery({
        queryKey: ['retrieveStudies', uid],
        queryFn: () => retrieveStudies(uid),
        throwOnError: handleHttpError,
        staleTime: 24 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchInterval: false,
    });
}

async function retrieveStudiesForOrg(
    orgID: string
): Promise<AxiosResponse<OrgStudyInfoResponse>> {
    const endpoint = findEndpointById("study-org")!
    var url = new URL(endpoint)
    url.searchParams.append('orgID', orgID)

    return await (axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    }))
}

export const useRetrieveStudiesForOrg = (orgID: string) => {
    const handleHttpError = useHandleHttpError()
    return useQuery({
        queryKey: ['retrieveStudiesForOrg', orgID],
        queryFn: () => retrieveStudiesForOrg(orgID),
        throwOnError: handleHttpError,
        staleTime: 24 * 60 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchInterval: false,
    });
};



