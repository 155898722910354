import { StartResetState } from "../../State/Auth/start.reset.state";
import { findEndpointById } from "../Endpoints/endpoints";
import axios from "axios";
import { SERVICE_ID } from "../../Constants/ui.constants";

export async function startReset(email: string): Promise<StartResetState> {
  try {
    const endpoint = findEndpointById("submitForgot")!;
    const resetResponse = await axios.post<StartResetState>(endpoint, email, {
      withCredentials: true,
      headers: {
        S: SERVICE_ID,
        "Content-Type": "text/plain"
      }
    });
    return resetResponse.data;
  } catch (error) {
    throw (error)
  }
}