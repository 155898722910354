import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { basic_info_page_content } from "../../../Content/basic.info";
import { Org } from "../../../Types/DAO/Org";
import { createNewDetailedInfo } from "../../../Types/SurveyData/Utils/detailed.info.utils";
import { DetailedInfo } from "../../../Types/SurveyData/detailed.info.type";
import { AutocompleteForm } from "../Components/autocomplete.form";
import { DataDate } from "../Components/data.dates";
import { GISForm } from "../Components/gis.form.row";
import { ProjectLeadsRow } from "../Components/project.leads.row";
import { RequiredPrompts } from "../Components/required.prompts";
import { detailedInfoSchema } from "../form.schemas";

import { CustomToggleButtonGroup } from "../Components/toggle.button.group";
import { GrantFundedStudy } from "../Components/grant.funded.study";
import { FieldDescription } from "../../Misc/FieldDescription";
import { AffiliatedIndividual } from "../../../Types/DAO/AffiliatedIndividual";
import { useLoadingIndicator } from "../../../Hooks/misc";

interface DetailedInformationFormProps {
    surveyOrgs: Org[] | null,
    surveySponsors: Org[] | null,
    setDetailedInfo: (d: DetailedInfo) => void,
    moveUp: (p: number) => void
}

//
// Notes about this form;  this form mostly embeds all the controls and labels as direct children of
// a single <Stack> element with exceptions for groups of elements which are conditional and/or
// repetitive and may be grouped visually.  One consequence of this is that margin declarations via
// CSS are suppressed by a CSS rule and you get the even spacing of the <Stack> which may or may not
// be what you like,  however you can eliminate this suppression by specifying the style element
// for a control
//
// The use of subforms is a bit of a missed opportunity in this form in terms of performance.  If
// we had one big component that wasn't broken into subcomponents, react-hook-form would be able to
// update form elements surgically when data changes.  It's still really fast now but it could be
// faster (on the other hand the current code is clearly organizations)
//

export const DetailedInformationForm: React.FC<DetailedInformationFormProps> = ({  setDetailedInfo, moveUp }) => {
    const methods = useForm({
        resolver: yupResolver(detailedInfoSchema),
        defaultValues: { ...createNewDetailedInfo() }
    })

    const { handleSubmit, control, getValues, watch} = methods
    const grantFunded = watch("grantFunded")
    const nonEmptyPerson = (i:AffiliatedIndividual) =>  i.affiliation !== null || i.lastName !== "" || i.firstName !==""
    const formValuesToDetailedInfo = (formValues: DetailedInfo): DetailedInfo => {
        return {
            ...formValues,
            surveyOrgs: formValues.surveyOrgs.filter(o => o.id !== ""),
            surveySponsors: formValues.surveySponsors.filter(o=>o.id !== ""),
            principalInvestigators: formValues.principalInvestigators.filter(nonEmptyPerson),
            funders: formValues.funders.filter(f=>f.affiliation!=null || f.grantNumber !==""),
            projectLeads: formValues.projectLeads.filter(nonEmptyPerson)
        }
    }

    const onSubmit = () => {
        setDetailedInfo(formValuesToDetailedInfo(getValues()))
        moveUp(1)
    }

    return useLoadingIndicator() || <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} title={"Describe the deposit"}>
            <Paper sx={{ padding: '30px', marginTop: '20px', marginBottom: '20px' }}>
                <Stack direction="column" sx={{ width: "100%" }} spacing={1.2}>
                    <Typography variant="h1" gutterBottom>
                        Describe the Deposit
                    </Typography>
                    <Typography variant="largeParagraph">
                        This section collects basic details about the deposit. On the next page, you will be able to
                        describe this deposit in more detail.
                    </Typography>
                    <Divider sx={{ mb: "5px" }} />
                    <RequiredPrompts header={"Title of the study"} req={true} minReq={false} />
                    <Controller
                      name="title"
                      control={control}
                      defaultValue=""
                      render={({ field, fieldState }) => (
                        <TextField
                          sx={{ marginTop: "10px" }}
                          {...field}
                          label="Title"
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          fullWidth
                        />
                      )}
                    />
                    <AutocompleteForm
                      name="surveyOrgs"
                      header={basic_info_page_content["survey.orgs"]["header"]}
                      lineText={basic_info_page_content['survey.orgs']['lines']}
                      req={true}
                      minReq={true}
                      type={basic_info_page_content["survey.orgs"]["buttonText"]}
                      singularObject={"organization"}
                    />
                    <FieldDescription>Enter the name(s) of any organization(s) below that cannot be found in the
                        dropdown control above</FieldDescription>
                    <Controller name={`displayOrg`}
                                control={control}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <TextField
                                    fullWidth
                                    sx={{
                                        marginTop: "10px", "& .MuiFormLabel-root": {
                                            fontSize: "14px",
                                        }
                                    }}
                                    label="Other survey organizations"
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : null}
                                  />
                                )} />
                    <AutocompleteForm
                      name="surveySponsors"
                      header={basic_info_page_content["survey.sponsors"]["header"]}
                      lineText={basic_info_page_content['survey.sponsors']['lines']}
                      req={false}
                      minReq={true}
                      type={basic_info_page_content["survey.sponsors"]["buttonText"]}
                      singularObject={"sponsor"}
                    ></AutocompleteForm>
                    <FieldDescription>Enter the name(s) of any sponsors(s) below that cannot be found in the dropdown
                        control above</FieldDescription>
                    <Controller name={`displaySponsor`}
                                control={control}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <TextField
                                    fullWidth
                                    sx={{
                                        marginTop: "10px", "& .MuiFormLabel-root": {
                                            fontSize: "14px",
                                        }
                                    }}
                                    label="Other survey sponsors"

                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : null}
                                  />
                                )} />
                    <CustomToggleButtonGroup
                      name={"grantFunded"}
                      header={basic_info_page_content["grant-funded"]["label"]}
                      yesText={basic_info_page_content["grant-funded"]["yes_text"]}
                      noText={basic_info_page_content["grant-funded"]["no_text"]}
                      value={grantFunded}
                    ></CustomToggleButtonGroup>
                    {grantFunded && <GrantFundedStudy />}
                    <ProjectLeadsRow />
                    <DataDate />
                    <GISForm />
                    <Button aria-label="continue" type='submit' variant="contained" fullWidth
                            sx={{ marginTop: "10px" }}>Continue</Button>

                </Stack>
            </Paper>
        </form>
</FormProvider>
}