import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { IMAGE_ASSETS } from "../../../Assets/img/getImg";
import { basic_info_page_content } from "../../../Content/basic.info";
import { createNewAddInfo } from "../../../Types/SurveyData/Utils/add.info.utils";
import { AddInfo, nonEmptyResponseRate, nonEmptySampling } from "../../../Types/SurveyData/add.info.type";
import { Publication } from "../Components/publications";
import { RequiredPrompts } from "../Components/required.prompts";
import { ResponseRate } from "../Components/response.rate";
import { Sampling } from "../Components/sampling";
import { addInfoSchema } from "../form.schemas";
import { CollectionModes } from "../../../Client/CollectionModes/collectionModes.client";
import { mapItems } from "../../../Util/functions";
import { CollectionMode } from "../../../Types/DAO/CollectionMode";
import { SamplingProcedures } from "../../../Client/SamplingProcedures/samplingProcedures.client";
import { DDErrorMessage } from "../Components/dderrormessage";
import { FieldDescription } from "../../Misc/FieldDescription";
import { useLoadingIndicator } from "../../../Hooks/misc";
import { UI_CONSTANTS } from "../../../Constants/ui.constants";

interface AdditionalInfoProps {
    title: string,
    setAddInfo: (a: AddInfo) => void,
    moveUp: (n: number) => void
    collectionModes: CollectionModes
    samplingProcedures: SamplingProcedures
}

//
// Notes about this form;  this form is less disciplined in the use of <Stack> than the
// DetailedInfoForm
//
//

export const AdditionalInfoForm: React.FC<AdditionalInfoProps> = ({ title, setAddInfo, moveUp, collectionModes,
                                                                  samplingProcedures}: AdditionalInfoProps) => {
    const methods = useForm({
        resolver: yupResolver(addInfoSchema),
        defaultValues: { ...createNewAddInfo() },
    })

    const { getValues, handleSubmit, watch } = methods
    const isEmbargo = watch("isEmbargo")

    const formValuesToAdditionalInfo = (values: AddInfo) => ({
        ...values,
        citations: values.citations.filter(s=>s!==""),
        sampling: values.sampling.filter(nonEmptySampling),
        responseRate: values.responseRate.filter(nonEmptyResponseRate)
    })

    const onSubmit = () => {
        setAddInfo(formValuesToAdditionalInfo(getValues()))
        moveUp(2)
    }

    return useLoadingIndicator() || (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box paddingLeft={"50px"} paddingRight={"50px"} marginTop="20px" paddingBottom={"50px"}>
                    <Stack direction="column" alignItems={"flex-start"} spacing={2}>
                        <Typography variant="h1" gutterBottom>
                            Additional information
                        </Typography>
                        <Typography variant="largeParagraph">
                            Please take a moment now to describe this deposit in more detail. The more information you provide, the more useful the data is for future research.
                        </Typography>
                        <Stack direction={"column"} sx={{ width: "100%" }}>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <Typography variant="asterisk" sx={{ color: UI_CONSTANTS["Carnellian"], fontSize: "12px" }}> * </Typography>
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                                            {basic_info_page_content["req.field"]}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemIcon>
                                        <img src={IMAGE_ASSETS["aapor"]} style={{ height: "15px", width: '15px' }} alt="AAPOR Logo" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="subtitle2" sx={{ fontSize: "12px" }}>
                                            {basic_info_page_content["min.req.field"]}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                            {/**Embargo */}
                            <Stack direction={"column"} sx={{ 'width': '100%' }} spacing={2}>
                                <Stack direction={"column"} sx={{ 'width': '100%' }} spacing={1}>
                                    <RequiredPrompts minReq={false} req={true} header="Is this study under embargo?"></RequiredPrompts>
                                    <Controller
                                        name="isEmbargo"
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <ToggleButtonGroup
                                                value={field.value}
                                                exclusive
                                                onChange={(_, v) => { field.onChange(v) }}
                                            >
                                                <ToggleButton value={true}>Yes</ToggleButton>
                                                <ToggleButton value={false}>No</ToggleButton>
                                            </ToggleButtonGroup>
                                        )}
                                    />
                                    {isEmbargo && <Controller name="embargoDate" defaultValue={new Date()} render={({ field, fieldState }) => (
                                        <DatePicker
                                            sx={{ width: "100%" }}
                                            label="Embargo Date"
                                            onChange={(v)=>{field.onChange(v)}}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    error: !!fieldState.error,
                                                    helperText: fieldState.error?.message,
                                                },
                                            }}
                                        ></DatePicker>
                                    )}></Controller>}
                                </Stack>
                                <Stack direction={"column"} sx={{ 'width': '100%' }} spacing={1}>
                                    <RequiredPrompts minReq={true} req={false} header="Universe/Population"></RequiredPrompts>
                                    <FieldDescription>
                                        The entire group of units which are the focus of the study, such as national adults, women age 65+, etc.
                                    </FieldDescription>
                                    <Controller
                                        name="universe"
                                        defaultValue={title}
                                        render={({ field }) => (
                                            <TextField
                                                multiline
                                                minRows={4}
                                                sx={{ marginTop: "10px" }} placeholder="Universe/population" {...field}></TextField>
                                        )}
                                    />
                                </Stack>
                                {/**Data collection methods */}
                                <Stack direction={"column"} sx={{ 'width': '100%' }} spacing={1}>
                                    <RequiredPrompts minReq={true} req={false} header="Mode of data collection (select all that apply)"></RequiredPrompts>
                                    <Controller
                                        name="modes"
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <FormGroup>
                                                {mapItems(collectionModes).map((method: CollectionMode) => {
                                                    return <FormControlLabel
                                                        key={method.modeId}
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                color="primary"
                                                                onChange={(e) => {
                                                                    const newValue = [...field.value]
                                                                    if (e.target.checked) {
                                                                        newValue.push(method.modeId)
                                                                    } else {
                                                                        newValue.splice(newValue.indexOf(method.modeId), 1);
                                                                    }
                                                                    field.onChange(newValue)
                                                                }}
                                                                checked={field.value.length === 0 ? false : field.value.includes(method.modeId)}
                                                                value={method.modeId}
                                                            />
                                                        }
                                                        label={method.modename}
                                                    />
                                                })}
                                            </FormGroup>
                                        )}
                                    />
                                </Stack>
                                {/**Sampling Error Component */}
                                <Stack direction={"column"} sx={{ 'width': '100%' }} spacing={1}>
                                    <RequiredPrompts minReq={true} req={false} header="Sampling error estimate"></RequiredPrompts>
                                    <FieldDescription>
                                        Report the estimated margin of sampling error and state whether or not the reported margins of sampling error or statistical analyses have been adjusted for the design effect due to weighting, clustering, or other factors.
                                    </FieldDescription>
                                    <Stack direction="row" alignContent={"space-evenly"} sx={{ flex: 1, flexGrow: 1, width: "100%" }} spacing={2}>
                                        <Controller
                                            name="samplingError.estimate"
                                            defaultValue={0}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">+/-</InputAdornment>,
                                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                                    }}
                                                    label="Sampling error estimate"
                                                    sx={{ marginTop: "10px" }} {...field}>
                                                </TextField>
                                            )}
                                        />
                                        <Controller
                                            name="samplingError.confLevel"
                                            defaultValue={0}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                                                    }}
                                                    label="Confidence level                                                "
                                                    sx={{ marginTop: "10px" }} {...field}></TextField>
                                            )}
                                        />
                                        <Controller
                                            name="samplingError.notes"
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <TextField
                                                    fullWidth
                                                    label="Notes"
                                                    sx={{ marginTop: "10px" }} {...field}></TextField>
                                            )}
                                        />
                                    </Stack>
                                    <DDErrorMessage name={"samplingError.estimate"}/>
                                    <DDErrorMessage name={"samplingError.confLevel"}/>
                                    {/**Weights */}
                                    <Stack direction="column" sx={{ 'width': "100%" }} spacing={1}>
                                        <RequiredPrompts minReq={true} req={false} header="Weight(s)"></RequiredPrompts>
                                        <FieldDescription>
                                            A description of the criteria for using weights in the analysis of a data collection. Please provide as much information as possible. In particular, please indicate the variable (s) to be used for weighting.
                                        </FieldDescription>
                                        <Controller
                                            name="weights"
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    placeholder="Weight(s)"
                                                    sx={{ marginTop: "10px" }} {...field}></TextField>
                                            )}
                                        />
                                    </Stack>

                                    {/**Description */}
                                    <Stack direction="column" sx={{ 'width': "100%" }} spacing={1}>
                                        <RequiredPrompts minReq={false} req={false} header="Description"></RequiredPrompts>
                                        <FieldDescription>
                                            Please provide a description of the subject matter, intellectual content, and purpose of this study.</FieldDescription>
                                        <Controller
                                            name="description"
                                            defaultValue={""}
                                            render={({ field }) => (
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    minRows={3}
                                                    placeholder="Description"
                                                    sx={{ marginTop: "10px" }} {...field}></TextField>
                                            )}
                                        />
                                    </Stack>
                                    <Sampling samplingProcedures={samplingProcedures} collectionModes={collectionModes}></Sampling>
                                    <ResponseRate collectionModes={collectionModes}/>
                                    <Publication/>
                                    <Button aria-label="continue" type="submit" variant="contained">Continue</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Box>
            </form>

        </FormProvider>
    );
}