import { Delete } from "@mui/icons-material"
import {
    Box,
    Button,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { RequiredPrompts } from "./required.prompts"
import { CollectionModes } from "../../../Client/CollectionModes/collectionModes.client";
import { useSet } from "../../../Util/functions";
import { MultiModeChooser } from "./multi.mode.chooser";
import { newResponseRate } from "../../../Types/SurveyData/add.info.type";
import { DDErrorMessage } from "./dderrormessage";
import { FieldDescription } from "../../Misc/FieldDescription";
interface ResponseRateProps {
    collectionModes: CollectionModes        // all the collection modes, not just the selected ones
}
export const ResponseRate = ({ collectionModes }: ResponseRateProps) => {
    const { getValues, setValue, watch } = useFormContext()
    const {fields} = useFieldArray(({name: 'responseRate'}))
    const selectedModes = useSet<string>(watch('modes'))

    return <Stack direction="column" sx={{ width: "100%" }} spacing={1} alignItems={"flex-start"}>
        <RequiredPrompts minReq={true} req={false} header="Response Rate"></RequiredPrompts>
        <FieldDescription>
            The percentage of respondents in the sample who participated in the study.
        </FieldDescription>
        <FieldDescription>
            <div>In the Definition field, please indicate which standard definition was used to calculate the response rate: AAPOR RR1, 2, 3, 4, 5, or 6, CASRO, or another definition. If another definition was used, please include a file with a thorough description in your file upload.</div>
        </FieldDescription>
       
        {fields.map((field, index) => {
            const percentageName = `responseRate[${index}].percentage`;
            const collectionModeName = `responseRate[${index}].collectionMode`;
            const definitionName = `responseRate[${index}].definition`;
            return (<><Box key={field.id} sx={{
                width: "100%",
                display: "grid",
                gridAutoFlow: "column",
                gridTemplateColumns: "115px 1fr 2fr 100px 30px",
                alignItems: "end",
                gap: 1,
            }}>
                <Controller
                  name={percentageName}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      InputProps={{
                          endAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                      type="number"
                      fullWidth
                      label="Percentage"
                      placeholder="Percentage"
                      sx={{ marginTop: "10px" }} {...field}></TextField>
                  )}
                />
                <Controller
                  name={definitionName}
                  defaultValue={""}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      label="Definition"
                      placeholder="Definition"
                      sx={{ marginTop: "10px" }} {...field}></TextField>
                  )}
                />
                <MultiModeChooser
                  name={collectionModeName}
                  collectionModes={collectionModes}
                  selectedModes={selectedModes}
                  label="Collection Mode"
                />
                <Controller
                  name={`responseRate[${index}].partial`}
                  defaultValue={true}
                  render={({ field }) => (
                    <FormControl fullWidth>
                        <InputLabel>Partial</InputLabel>
                        <Select
                          label="Partial"
                          fullWidth
                          value={field.value ? "Yes" : "No"}
                          onChange={(e) => field.onChange(e.target.value === "Yes")}
                        >
                            {["Yes", "No"].map((mode: string, index: number) => (
                              <MenuItem key={index} value={mode}>
                                  {mode}
                              </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                  )}
                />
                <Button
                  aria-label="remove-rr" sx={{ mb: 1.5 }}
                  onClick={() => {
                      const samplings = [...getValues("responseRate")];
                      samplings.splice(index, 1);
                      setValue("responseRate", samplings);
                  }}><Delete></Delete></Button>
            </Box>
            <DDErrorMessage name={percentageName}/>
            <DDErrorMessage name={definitionName}/>
            <DDErrorMessage name={collectionModeName}/>
            </>)
        })}
        <Button
        onClick={() => {
            setValue("responseRate", [...getValues("responseRate"), newResponseRate])
        }}> Add response rate</Button>


    </Stack>


}