export const UI_CONSTANTS = {
    "Carnellian": "#B31B1B",
    "Dark Carnellian": "#7F0000",
    "L Carnellian": "#D24D4D",
    "L Dark Carnellian": "#A63232"

}

export const SERVICE_ID = 'Data-Provider';

export const SUPPORT_EMAIL = "data-services@ropercenter.org";
export const SUPPORT_EMAIL_LINK = `mailto:${SUPPORT_EMAIL}`;
export const SUPPORT_ANCHOR = <a href={SUPPORT_EMAIL_LINK}
                                 style={{ whiteSpace: 'nowrap' }}>{SUPPORT_EMAIL}</a>

export const ROOT_URL = "/provider"