import { DriveFolderUpload } from "@mui/icons-material";
import { Container, Skeleton, Stack, Typography } from "@mui/material";
import { DataGrid, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { useRetrieveStudies } from "../../../Client/Study/study.client";
import { useID } from "../../../Hooks/Auth/useAuthStatus";
import { ErrorOverlay } from "../../Misc/ErrorOverlay";
import { submissionCols, studyStatus } from "../../Misc/Grid";
interface SubmissionPageProps {
    // none!
}

export const renderErrorOverlay = () => { return <ErrorOverlay message={"No data"}></ErrorOverlay> }


export const SubmissionPage: React.FC<SubmissionPageProps> = () => {

    const [rows, setRows] = useState<GridRowsProp>([]);
    const id = useID();
    const { isPending, data } = useRetrieveStudies(id ?? "");

    const calculateStatus = (study: any) => {
        if (!study) {
            return studyStatus.UNKNOWN;
        }
        if (!study.submittedDate) {
            return studyStatus.PENDING;
        } else if (!study.publishedDate) {
            return studyStatus.WIP;
        } else {
            return studyStatus.COMPLETE;
        }
    };
    useEffect(() => {
        if (data?.data && data.data.data.length > 0) {
            const formattedRows = data.data.data.map(submission => ({
                ...submission,
                date: new Date(submission.submittedDate),
                status: calculateStatus(submission),
            }));
            setRows(formattedRows);
        }
    }, [data]);

    return <Container component="main">
        <Stack direction="column" alignItems={"flex-start"} spacing={2}>
            <Stack direction="row" alignItems={"center"} spacing={1}>
                <DriveFolderUpload color="primary" style={{fontSize: "3rem", paddingRight: '1rem'}}></DriveFolderUpload>
                <Typography variant="h1" color="primary" gutterBottom>
                    Past Submissions
                </Typography>
            </Stack>

            {isPending && <Skeleton data-testid="loading" sx={{ width: "100%", height: "100%" }}></Skeleton>}
            {data && !isPending && <div style={{ height: "700px", width: "100%" }}>
                <DataGrid
                    sx={{

                        width: "100%", boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        }
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'date', sort: 'desc' }],
                        },
                    }}
                    rows={rows}
                    columns={submissionCols}
                    slots={{
                        noRowsOverlay: renderErrorOverlay,
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            printOptions: { disableToolbarButton: true }
                        },
                    }}
                ></DataGrid></div>}
        </Stack>
    </Container>
}