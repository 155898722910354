import React, { FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Card, CardContent, CircularProgress, Container, Snackbar, Stack, TextField } from "@mui/material";
import { IMAGE_ASSETS } from "../../Assets/img/getImg";
import { Alert } from "../Misc/Alert";
import { useUIStatus } from "../../Hooks/UI/useModeStatus";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { resetPassword, validateToken } from "../../Client/Auth/finish.reset.client";
import { SUPPORT_ANCHOR } from "../../Constants/ui.constants";

type NewPasswordProps = {
  error: boolean,
  setError: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  handleSubmit: (event: FormEvent) => void,
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  password: string,
}

function NewPasswordForm({error, setError, handleSubmit, handlePasswordChange, password}: NewPasswordProps) {
  const [eyeOpen, setEyeOpen] = useState(false);

  return <>
    <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
      <Alert onClose={() => setError(false)} severity="error" sx={{ width: "100%" }}>
        Invalid credentials.
      </Alert>
    </Snackbar>
    <Card sx={{ mt: 5, backgroundColor: "lightblue" }} elevation={2}>
      <CardContent sx={{ fontFamily: "sans-serif", textAlign: "start" }}>
        <p>
          <strong>Password reset step 2 of 2:</strong> Please enter a new password to restore access to your Roper
          Provider Portal account.
        </p>
      </CardContent>
    </Card>
    <form style={{ marginTop: "20px" }} onSubmit={handleSubmit}>
      <Stack spacing={2}>
        <TextField
          inputProps={{ "data-testid": "password-field" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setEyeOpen(!eyeOpen)}
                  edge="end"
                >
                  {!eyeOpen ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label="Password"
          role="password-field"
          type={eyeOpen ? "text" : "password"}
          variant="outlined"
          fullWidth
          required
          value={password}
          onChange={handlePasswordChange}
        />
        <Button
          aria-label="login"
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          data-testid="submit-btn"
        >
          Change my password
        </Button>
      </Stack>
    </form>
  </>
}


const Loading = () =>
  <CircularProgress sx={{ textAlign: "center", mt: 2 }}></CircularProgress>

const InvalidResetToken =  () => <Card sx={{ mt: 5, backgroundColor: "lightblue" }} elevation={2}>
  <CardContent sx={{ fontFamily: "sans-serif", textAlign: "start" }}>
    <p>
      <strong>Your password reset token is invalid:</strong> If you cut and pasted the URL it is possible that the
      token got mangled,  it is also possible that your password reset token has already been used.  You can have us
      send you a <a href="/provider/auth/startReset">new password reset token</a> in an email or
      contact {SUPPORT_ANCHOR} for help.
    </p>
  </CardContent>
</Card>

const ResetComplete =  () => <Card sx={{ mt: 5, backgroundColor: "lightblue" }} elevation={2}>
  <CardContent sx={{ fontFamily: "sans-serif", textAlign: "start" }}>
    <p>
      <strong>Your password has been reset:</strong>{" "}
      Visit the <a href={"/provider/"}>login form</a> to log in
    </p>
  </CardContent>
</Card>

const ResetFailed =  () => <Card sx={{ mt: 5, backgroundColor: "lightblue" }} elevation={2}>
  <CardContent sx={{ fontFamily: "sans-serif", textAlign: "start" }}>
    <p>
      <strong>Your password reset failed:</strong> You can have us
      send you a <a href="/provider/auth/startReset">new password reset token</a> in an email or
      contact {SUPPORT_ANCHOR} for help.
    </p>
  </CardContent>
</Card>

type TokenState = "unknown" | "valid" | "invalid" | "complete" | "failed"

const isOk = (statusCode: number) => statusCode>=200 && statusCode<300

const FinishPasswordResetForm: React.FC = () => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false)
  const [tokenState, setTokenState] = useState<TokenState>("unknown")
  const mode = useUIStatus()

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("t") || "no token";
  useEffect(() => {
    validateToken(token).then((status) => {
      setTokenState(isOk(status) ? "valid" : "invalid")
    }).catch(() => setTokenState("invalid"));
  }, [token])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    resetPassword(token, password).then((status) => {
      setTokenState(isOk(status) ? "complete" : "failed")
    }).catch(() => setTokenState("failed"));
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  return <div data-testid="finish-reset-password-container">
    <Container maxWidth="sm" style={{ marginTop: "64px", textAlign: "center", width: "100vw", alignItems: "center" }}>
      <img style={{ width: "300px" }}
           src={mode === "light" ? IMAGE_ASSETS["roper_full"] : IMAGE_ASSETS["roper_full_light"]}
           alt="roper-logo"></img>
      {tokenState === "valid"
        ? <NewPasswordForm error={error} setError={setError} handleSubmit={handleSubmit}
                           password={password} handlePasswordChange={handlePasswordChange} />
        : tokenState === "invalid" ? <InvalidResetToken/>
        : tokenState === "complete" ? <ResetComplete/>
        : tokenState === "failed" ? <ResetFailed/> : <Loading/>}
    </Container></div>
}

export default FinishPasswordResetForm;