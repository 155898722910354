export const API_HOST = process.env && process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : `http://${window.location.hostname}:1947`;
export type Endpoint = {
    path: string,
    children: Endpoint[],
    uid:string
}

const ep = (path: string, children?: Endpoint[]) => { return { path: path, uid: path, children: children ?? []}}

//
// Tree structure of API calls that this application makes;  the application looks up calls using the
// 'uid' and the functions in this file will traverse the tree to determine the URL.  Note uid==path
// most of the time but there are some counterexamples.
//
export const API_ENDPOINTS: Endpoint[] = [
    {
        path: "api",
        uid: "api",
        children: [
            ep("reset-confirm"),
            {
                path: "dd",
                uid: "dd",
                children: [
                    {
                        path: "gis",
                        uid: "gis",
                        children: [
                            ep("countries"),
                            ep("states"),
                            ep("cities")
                        ],
                    },
                    ep("report"),
                    ep("login"),
                    ep("submitForgot"),
                    {
                        path:"report",
                        uid:"report",
                        children:[
							{
								path:"usage",
                        		uid:"usage",
                        		children:[]
                    		},
							{
								path:"survey",
                        		uid:"survey",
                        		children:[]
                    		}
						]
                    },
                    
                    {
                        path: "login",
                        uid:'login',
                        children: []
                    }, {
                        path: "org",
                        uid: "org",
                        children: [
                            {
                                path: "read",
                                uid: "read-org-by-id",
                                children: [],
                            },
                        ],
                    }, {
                        path: "study",
                        uid: "study",
                        children: [
                            ep("files"),
                            ep("depositorfiles"),
                            ep("downloadfile"),
                            ep("allfiles"),
                            ep("save"),
                            {
                                path: "org",
                                uid: "study-org",
                                children: [],
                            }
                        ],
                    }, {
                        path: "util",
                        uid: "util",
                        children: [
                            ep("keywords"),
                            ep("status")
                        ],
                    }, {
                        path: "deposit",
                        uid: "deposit",
                        children: [
                            ep("boxURL")
                        ],
                    },
                ],
            }, {
                path: "data",
                uid: "data",
                children: [
                  ep("collectionModes"),
                  ep("samplingProcedures"),
                ],
            },
            ep("feedback")
        ],
    },
]

// Note that this function does a full scan of API_ENDPOINTS to find an API call
// specified by id;  contrast that to the possibility of figuring out all the paths once
// and storing them in an object.  This implementation is fine now because we don't have many
// different API calls and we don't make them all that often.

const accumulateEndpoint = (id: string, acc: string, curr: Endpoint): string | null => {
    acc = acc + "/" + curr.path;
    if (curr.uid === id) return acc;
    for (const child of curr.children) {
      const result = accumulateEndpoint(id, acc, child);
      if (result !== null) {
        return result;
      }
    }
    return null; 
  };
  
  export const findEndpointById = (id: string): string | null => {
    for (const endpoint of API_ENDPOINTS) {
      const result = accumulateEndpoint(id, API_HOST, endpoint);
      if (result !== null) {
        return result; 
      }
    }
    return null;
  };