import { useEffect, useState } from "react";
import { findEndpointById } from "../Endpoints/endpoints";
import axios from "axios";
import { CollectionMode } from "../../Types/DAO/CollectionMode";
import { SERVICE_ID } from "../../Constants/ui.constants";
import { useHandleHttpError } from "../../Hooks/misc";

export type CollectionModes = Map<string, CollectionMode>

async function getCollectionModes(): Promise<CollectionModes> {
    const endpoint = findEndpointById("collectionModes")!;
    var url = new URL(endpoint);
    var promise = await axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    });
    const modeList: CollectionMode[] = await promise.data.data
    const modes = new Map<string, CollectionMode>()
    modeList.forEach((mode) => modes.set(mode.modeId, mode))
    return modes
}

// A nice example
//
// Note that this hook is exported but getCollectionModes is not,  which is nice because it
// lets us handle errors centrally.  particularly we can use useDispatch() om the hook which
// makes it easy on the caller
//

export function useCollectionModes(): CollectionModes {
    const [collectionModes, setCollectionModes] = useState<CollectionModes>(new Map<string, CollectionMode>())
    const handleHttpError = useHandleHttpError()
    useEffect(() => {
        getCollectionModes().then((x) => setCollectionModes(x)).catch(handleHttpError);
    }, [handleHttpError]);
    return collectionModes;
}