import { Stack, ToggleButton, Typography } from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Controller } from "react-hook-form";
import { FieldDescription } from "../../Misc/FieldDescription";
interface ToggleButtonGroupProps {
    name: string,
    header: string,
    yesText: string,
    noText: string,
    value: boolean
}

export const CustomToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
    name,
    header,
    yesText,
    value,
    noText,
}: ToggleButtonGroupProps) => {
    
    return (
        <Stack direction="column" spacing={1} marginTop={"20px"}>
            <FieldDescription>{header}</FieldDescription>
            <Controller name={name} render={({field}) => <ToggleButtonGroup
              value={value ? "yes": "no"}
              exclusive
              onChange={(_, newValue) => {field.onChange(newValue==="yes") }}
              aria-label={header}
            >
                <ToggleButton value={"yes"}>
                    <Typography variant='toggleButton'>{yesText}</Typography>
                </ToggleButton>

                <ToggleButton value={"no"}>
                    <Typography variant='toggleButton'>{noText}</Typography>
                </ToggleButton>
            </ToggleButtonGroup>}/>
        </Stack>
    )
};
