import { Button, Stack } from "@mui/material";
import { AutocompleteCell } from "./autocomplete.cell";
import { RequiredPrompts } from "./required.prompts";
import { FieldDescription } from "../../Misc/FieldDescription";
import { useFieldArray } from "react-hook-form";
import { emptyOrg } from "../../../Types/DAO/Org";
import { DDErrorMessage } from "./dderrormessage";
import React from "react";

interface AutocompleteFormProps {
    name: string,
    header: string,
    lineText: string[],
    type: string,
    minReq: boolean,
    req: boolean,
    singularObject: string
}

//
// multiple selection autocomplete component which expands to fill the whole line;  as opposed to
// selecting multiple items inside the <Autocomplete> we create multiple <AutocompleteCell>(s),
// one for each item
//
// used for survey organization and sponsors
//

export const AutocompleteForm: React.FC<AutocompleteFormProps> = ({
    name,
    header,
    lineText,
    minReq,
    req,
    singularObject
}: AutocompleteFormProps) => {
    //Options are what is selected
    const {fields,insert, remove} = useFieldArray({name})

    return (
        <>
            <RequiredPrompts header={header} req={req} minReq={minReq} />
            {lineText.map((text: string, index: number) => {
                return (<FieldDescription key={index}>{text}</FieldDescription>)
            })}
            <Stack spacing={1}
                sx={{
                    maxHeight: "300px",
                    overflowY: "scroll",
                    width: "100%",
                    paddingTop: "10px",
                    alignItems: "stretch"
                }}
            >
                {fields.map(({id}, index) => (
                    <div key={id}>
                        <AutocompleteCell
                            name={name}
                            header={header}
                            index={index}
                            remove={remove}
                            deleteDisabled={fields.length<=1}
                        />
                    </div>
                ))}
              <DDErrorMessage name={name}/>
              <Button
                  sx = {{alignSelf: "flex-start"}}
                  onClick={() => { insert(fields.length, emptyOrg) }}>Add {singularObject}</Button>
            </Stack>
        </>
    )
};
