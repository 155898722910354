import { Delete } from "@mui/icons-material";
import {
    Autocomplete,
    Button,
    CircularProgress,
    Stack,
    TextField
} from "@mui/material";

import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { getOrgs, sameId } from "../../../Client/Org/org.client";
import { Org } from "../../../Types/DAO/Org";
import { useHandleHttpError } from "../../../Hooks/misc";

type AutocompleteCellProps = {
    name: string
    header: string
    index: number
    remove: (i: number) => void
    deleteDisabled: boolean
};

//
// a single line in an <AutocompleteForm> for choosing a single item
//

export const AutocompleteCell: React.FC<AutocompleteCellProps> = ({
    name,
    header,
    index,
    remove,
    deleteDisabled
}: AutocompleteCellProps) => {
    const handleHttpError = useHandleHttpError()
    const [query, setQuery] = useState('')
    const [fetching, setFetching] = useState(false);
    const [autocompleteOptions, setAutocompleteOptions] = useState<Org[]>([]);

    const getOptionsDelayed = useMemo(() => debounce(
        (query: string, callback: (data: Org[]) => void) => {
            if (query.length >= 3) {
                setAutocompleteOptions([])
                setFetching(true)
                getOrgs(query).then((r) => callback(r.data.data))
                  .catch(handleHttpError)
            }
        },
        500
    ),[handleHttpError]);

    useEffect(() => {
        if (query !== "") {
            getOptionsDelayed(query, (options: Org[]) => {
                if (Array.isArray(options)) {
                    setFetching(false)
                    setAutocompleteOptions(options);
                } else {
                    console.log(options)
                    console.error("options is not an array");
                }
            });
        }
    }, [query, getOptionsDelayed]);

    return (
        <Stack direction={"row"} alignItems="center" justifyContent="space-between">
            <Controller
              name={name+"["+index+"]"}
              render={({ field, fieldState }) =>
                <Autocomplete
                    {...field}
                    fullWidth
                    isOptionEqualToValue={sameId}
                    options={autocompleteOptions}
                    onChange={(_, val) => {
                        field.onChange(val);
                    }}
                    getOptionLabel={(option) => option.primaryName}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        sx={{
                            fontSize: "15px",
                            "& .MuiFormLabel-root": {
                                fontSize: "14px",
                            },
                        }}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <div>
                                  {fetching ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                              </div>
                            ),
                        }}
                        label={header}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                      />
                    )}
              />}
            />

            <Button
                aria-label="remove-option-button"
                disabled={deleteDisabled}
                onClick={() => { remove(index) }}
            >
                <Delete sx={{ fontSize: "20px" }} />
            </Button>
        </Stack>
    );
}


