import {
  Button, CircularProgress, Container, Snackbar, Stack, TextField,
  Card, CardContent,
} from "@mui/material";

import React, { useState } from 'react';
import { IMAGE_ASSETS } from "../../Assets/img/getImg";
import { useUIStatus } from "../../Hooks/UI/useModeStatus";
import { Alert } from "../Misc/Alert";
import { StartResetState } from "../../State/Auth/start.reset.state";
import { startReset } from "../../Client/Auth/start.reset.client";
import { useAppDispatch } from "../../Redux/Hooks/hooks.redux";
import { AxiosError } from "axios";
import { setNetworkState } from "../../Redux/Reducer/network.state.reducer";
import { SUPPORT_ANCHOR } from "../../Constants/ui.constants";

type gatherResetEmailProps = {
  mode: "light" | "dark",
  error: boolean,
  setError: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
  email: string,
  handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  processing: boolean};

type resetPasswordErrorProps = {
  mode: "light" | "dark",
  email: string,
}

function GatherResetEmail({mode,error,setError,handleSubmit,email,handleEmailChange,processing}: gatherResetEmailProps) {

  return (
    <div data-testid="reset-password-container">
      <Container maxWidth="sm" style={{ marginTop: "64px", textAlign: "center", width: "100vw", alignItems: "center" }}>
        <img style={{ width: "300px" }}
             src={mode === "light" ? IMAGE_ASSETS["roper_full"] : IMAGE_ASSETS["roper_full_light"]}
             alt="roper-logo"></img>
        <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
          <Alert onClose={() => setError(false)} severity="error" sx={{ width: "100%" }}>
            Invalid credentials.
          </Alert>
        </Snackbar>
        <Card sx={{ mt: 5, backgroundColor: "lightblue" }} elevation={2}>
          <CardContent sx={{ fontFamily: "sans-serif", textAlign: "start" }}>
            <p>
              <strong>Password reset step 1 of 2:</strong> Enter the email address that you use to log into the data
              deposit portal into the field below and we'll send an email to that address with a special
              link that will let you reset your password.
            </p>
          </CardContent>
        </Card>
        <form style={{ marginTop: "20px" }} onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              inputProps={{ "data-testid": "email-field" }}

              fullWidth
              required
              value={email}
              onChange={handleEmailChange}
            />
            <Button
              aria-label="login"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={processing}

              data-testid="submit-btn"
            >
              Start password reset
            </Button>
          </Stack>
          {processing && <CircularProgress sx={{ textAlign: "center", mt: 2 }}></CircularProgress>}

        </form>

      </Container>
    </div>
  );
}

function CheckEmail({mode, email}: resetPasswordErrorProps) {
  return <div data-testid="reset-password-container">
    <Container maxWidth="sm" style={{ marginTop: "64px", textAlign: "center", width: "100vw", alignItems: "center" }}>
      <img style={{ width: "300px" }}
           src={mode === "light" ? IMAGE_ASSETS["roper_full"] : IMAGE_ASSETS["roper_full_light"]}
           alt="roper-logo"></img>
      <Card sx={{ mt: 5, backgroundColor: "lightblue" }} elevation={2}>
        <CardContent sx={{ fontFamily: "sans-serif", textAlign: "start" }}>
          <p>
            <strong>Check your email:</strong> Your password reset request is being processed. We have sent further instructions
            to {email}.
          </p>
        </CardContent>
      </Card>
    </Container>
  </div>
}

function RequestFailed({mode, email}: resetPasswordErrorProps) {
  return <div data-testid="reset-password-container">
    <Container maxWidth="sm" style={{ marginTop: "64px", textAlign: "center", width: "100vw", alignItems: "center" }}>
      <img style={{ width: "300px" }}
           src={mode === "light" ? IMAGE_ASSETS["roper_full"] : IMAGE_ASSETS["roper_full_light"]}
           alt="roper-logo"></img>
      <Card sx={{ mt: 5, backgroundColor: "lightblue" }} elevation={2}>
        <CardContent sx={{ fontFamily: "sans-serif", textAlign: "start" }}>
          <p>
            <strong>Password reset failed:</strong> We are unable to do a self-service password reset for {email},
            contact {SUPPORT_ANCHOR} for assistance.
          </p>
        </CardContent>
      </Card>
    </Container>
  </div>
}

export const StartResetForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [resetState, setResetState] = useState<StartResetState>({ status: undefined, associationId: "" })
  const dispatch = useAppDispatch();

  const mode = useUIStatus()
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setProcessing(true)
    setError(false)
    console.log("the recorded email is ", email)
    startReset(email).then((response: StartResetState) => {
      setResetState(response)
    }).catch((e: AxiosError) => {
      setError(true)
      if (e.response && e.response.status && e.response.status !== 401) {
        dispatch(setNetworkState(true))
      }
    }).finally(() => setProcessing(false))
  };

  return resetState.status === undefined ?
    GatherResetEmail({ mode, error, setError, handleSubmit, email, handleEmailChange, processing })
    : resetState.status === "checkEmail" ? CheckEmail({ mode, email }) : RequestFailed({ mode, email })
}
