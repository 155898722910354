import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useMemo } from 'react';
import { NavLink } from "react-router-dom";
import { MenuItem as MenuItemType, NestedMenuProps } from "./NestedMenuProps";
import { useNetworkStatus } from "../../../Hooks/Network/useNetworkState";

interface RenderMenuItemProps {
    item: MenuItemType;
    path: string;
}

interface ConditionalLinkProps {
    to: string;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

//
// When the application goes into the failed state, none of the links in the navigation bar works to navigate out of
// the error page because these are being routed by react-router inside a dead app,  this turns the link into an
// <a href> which will force a reload and possibly restore app function
//

export const ConditionalLink: React.FC<ConditionalLinkProps> = ({ to, children, style }) => {
    const networkStatus = !!useNetworkStatus();

    return <NavLink to={to} style={style} reloadDocument={networkStatus}>{children}</NavLink>;
}

const RenderMenuItem: React.FC<RenderMenuItemProps> = ({ item, path }) => {
    const itemPath = path ? `${path}/${item.id}` : item.id;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const itemKey = useMemo(() => `${path}-${item.id}`, [path, item.id]);

    if (!item.children) {
        return (
            <ConditionalLink to={itemPath} style={{ textDecoration: "none" }}>
                <Box key={itemKey} sx={{ minHeight: '44px', m: '0px 20px 0px 20px', alignContent: 'center' }}>
                    <Typography data-testid="nested-menu-item" variant="uiControl" color={"white"}>{item.name}</Typography>
                </Box>
            </ConditionalLink>
        );
    }

    return (
        <Box key={itemKey} sx={{ m: '10px 10px 10px 10px' }}>
            <Button
                aria-controls={open ? `menu-${itemKey}` : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                variant="text"
                sx={{ height: '44px', textTransform: "none" }}
            >   <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{ width: '100%' }}>
                    <Typography data-testid="nested-menu-item" variant="uiControl" color={"white"}>{item.name}</Typography>
                    {!open ? <ExpandMore sx={{ color: "white" }} ></ExpandMore> : <ExpandLess sx={{ color: "white" }}></ExpandLess>}
                </Stack>
            </Button>
            <Menu
                id={`menu-${itemKey}`}
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            bgcolor: 'primary.main',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        },
                    },
                }}
            >
                {item.children.map((child, idx) => (
                    <MenuItem key={`${itemKey}-${idx}`} onClick={handleClose}>
                        <RenderMenuItem item={child} path={`${itemPath}`} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export const NestedMenu: React.FC<NestedMenuProps> = ({ items }) => {
    return (
        <React.Fragment>
            {items.map((item, index) => (
                <RenderMenuItem key={index} item={item} path={""} />
            ))}
        </React.Fragment>
    );
};
