import { createTheme } from '@mui/material/styles';
import { UI_CONSTANTS } from '../Constants/ui.constants';
import { UIMode } from '../State/UI/UI.state';
export function createAppTheme(mode: UIMode) {
    const textDefaults = {
        fontFamily: "Lato",
        color: mode === "dark" ? "white" : "black",
    };
    return createTheme({
        typography:
        {
            allVariants: {
                ...textDefaults,
            },
            uiControl: {
                ...textDefaults,
                fontSize: 16,
            },
            largeParagraph: {
                ...textDefaults,
                fontSize: "1.2rem"
            },
            modalParagraph: {
                ...textDefaults,
                fontSize: "1.0rem"
            },
            h1: {                    // title of form
                ...textDefaults,
                fontSize: "1.75rem"
            },
            h2: {                    // name of a section (group of UI controls) in the form
                ...textDefaults,
                fontWeight: "bold",
                fontSize: "1.4rem"
            },
            h3: {                    // name of a subsection in the form,  looks the same as an
                ...textDefaults,     // h2 but is logically subordinate, see grant.funded.study.tsx
                fontWeight: "bold",
                fontSize: "1.4rem"
            },
            fieldDescription: {
                ...textDefaults,
                fontSize: "1rem"
            },
            toggleButton: {
                ...textDefaults,
                fontSize: "12px"
            },
            asterisk: {
                ...textDefaults,
                color: UI_CONSTANTS["Carnellian"],
                fontSize: '20px'
            },
            h3red: {
                ...textDefaults,
                color: UI_CONSTANTS["Carnellian"],
                fontSize: '1.1rem'
            },
            orgLabel: {
                ...textDefaults,
                fontSize: '1.5rem'
            },
            littleLabel: {
                ...textDefaults,
                fontSize: '1.2rem',
                color: UI_CONSTANTS["L Dark Carnellian"]
            },
            h1home: {
                ...textDefaults,
                fontSize: '2.5rem'
            },
            h2home: {
                ...textDefaults,
                fontSize: '1.5rem'
            }
        },
        palette: {
            mode: mode,
            primary: {
                main: mode === "light" ? UI_CONSTANTS["Carnellian"] : UI_CONSTANTS["L Carnellian"],
                dark: mode === "light" ? UI_CONSTANTS["Dark Carnellian"] : UI_CONSTANTS["L Dark Carnellian"],
            },
        },
        components: {
            MuiContainer: {
                defaultProps: {
                    maxWidth: false
                },
                styleOverrides: {
                    root: {
                        paddingTop: '25px'
                    }
                }
            },
            MuiStepLabel: {
                styleOverrides: {
                    label: {
                        fontSize: '4rem'
                    }
                }
            },
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        h3red: 'h3',
                        h1home: 'h1',
                        h2home: 'h2',
                        modalParagraph: 'p',
                        largeParagraph: 'p',
                        subtitle2: 'div'
                    }
                }
            }
        }
    })
}
