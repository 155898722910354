import { Stack, Typography } from "@mui/material";
import { IMAGE_ASSETS } from "../../../Assets/img/getImg";

interface RequiredPromptsProps {
    req: boolean,
    minReq: boolean,
    header: string,
    id?: string
}

export const
  RequiredPrompts: React.FC<RequiredPromptsProps> = ({ req, minReq, header, id }: RequiredPromptsProps) => {
    return (
        <Stack direction="row" spacing={1} alignItems={"center"}>
            <Typography id={id} variant="h2" gutterBottom>{header}</Typography>
            {req && <Typography variant="asterisk"> * </Typography>} {/* Smaller font size */}
            {minReq && <img src={IMAGE_ASSETS["aapor"]} style={{ height: "15px", width: '15px' }} alt="AAPOR Logo" />} {/* Smaller logo */}
        </Stack>
    )
};
