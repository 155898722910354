//
// autocomplete element which can used separately from AutocompleteForm
//

import { AnyAction, Middleware, MiddlewareArray, ThunkMiddleware } from "@reduxjs/toolkit";
import { EmptyObject } from "react-hook-form";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { setNetworkState } from "../Redux/Reducer/network.state.reducer";
import { AxiosError } from "axios";
import { setAuthState } from "../Redux/Reducer/app.state.reducer";
import { useAppDispatch } from "../Redux/Hooks/hooks.redux";
import { verifyStatus } from "../Client/Util/network.status.client";
import { Box, CircularProgress } from "@mui/material";

export type IsAny<T, True, False = never> =
// test if we are going the left AND right path in the condition
    true | false extends (T extends never ? true : false) ? True : False

type ExtractDispatchFromMiddlewareTuple<
  MiddlewareTuple extends any[],
  Acc extends {}
> = MiddlewareTuple extends [infer Head, ...infer Tail]
  ? ExtractDispatchFromMiddlewareTuple<
    Tail,
    Acc & (Head extends Middleware<infer D> ? IsAny<D, {}, D> : {})
  >
  : Acc

export type DispatchFunction = [{} extends {
  thunk: { extraArgument: infer E }
} ? ThunkMiddleware<EmptyObject, AnyAction, E> : ThunkMiddleware<EmptyObject, AnyAction>] extends MiddlewareArray<infer MiddlewareTuple> ? ExtractDispatchFromMiddlewareTuple<MiddlewareTuple, {}> : ([{} extends {
  thunk: { extraArgument: infer E }
} ? ThunkMiddleware<EmptyObject, AnyAction, E> : ThunkMiddleware<EmptyObject, AnyAction>] extends ReadonlyArray<Middleware> ? ExtractDispatchFromMiddlewareTuple<[...[{} extends {
  thunk: { extraArgument: infer E }
} ? ThunkMiddleware<EmptyObject, AnyAction, E> : ThunkMiddleware<EmptyObject, AnyAction>]], {}> : never) & Dispatch<AnyAction>;

function handleHttpError(dispatch: DispatchFunction) {
  return (error:AxiosError) => {
    if(error.status===401||error.status===403) {
      dispatch(setAuthState({ boxURL: "", isAuth: false, lastLoggedIn: undefined, org: undefined, user: undefined }))
    } else {
      dispatch(setNetworkState(true));
    }
    return false  // helps useReportingQuery do the right thing
  }
}

//
// returns a handleHttpError function which should be called at the end of any promise chain that
// involves an http request to properly handle cases where the user loses their cookie or otherwise the back
// end errors or becomes unavailable
//

export function useHandleHttpError() {
  const dispatch = useAppDispatch();
  return useMemo(() =>handleHttpError(dispatch), [dispatch]);
}

//
// returns false the first time it is called,  generates a request to test network status and
// returns a loading indicator until the verification is complete
//

export function useLoadingIndicator() {
  const [verified, setVerified] = useState<boolean>(false);
  const handleHttpError = useHandleHttpError();
  useEffect(
    () => {verifyStatus().then(()=>setVerified(true)).catch(handleHttpError)},
    [handleHttpError]
  )
  return verified ? false :
    <Box display="flex" justifyContent="center">
      <CircularProgress size={100} sx={{marginTop: "25px"}}/>
    </Box>
}