import { User } from "../../Types/User";
import { findEndpointById } from "../Endpoints/endpoints";
import axios from "axios";
import { SERVICE_ID } from "../../Constants/ui.constants";

//
// checks the validity of a token without actually using it;  returns the http status code
// of the request.  If the token is valid,  returns a 200 or possibly a 201,  if the token
// is invalid it will normally return a 400.  Problems on the back end could cause a 500
//
export async function validateToken(token: string): Promise<number> {
  try {
    const endpoint = findEndpointById("reset-confirm")!
      + "?t=" + encodeURIComponent(token);
    const resetResponse = await axios.get<User>(endpoint, {
      withCredentials: true,
      headers: {
        S: SERVICE_ID,
        "Content-Type": "text/plain"
      }
    });
    return resetResponse.status
  } catch (error) {
    throw (error)
  }
}

//
// performs a password reset;  returns the http status code of the request.  If the token
// is valid,  returns a 200 or possibly a 201,  if the token is invalid it will normally
// return a 400.  Problems on the back end could cause a 500
//

export async function resetPassword(token: string, newPassword: string): Promise<number> {
  try {
    const endpoint = findEndpointById("reset-confirm")!;
    const body = {
      password: newPassword,
      resetToken: token
    }
    const resetResponse =  await axios.post(endpoint, body, {
      withCredentials: true,
      headers: {
        S: SERVICE_ID,
        'Content-Type': 'application/json'
      }
    })
    return resetResponse.status
  } catch(error) {
    throw(error)
  }
}