import { Avatar, Box, Button, Menu, MenuItem, AppBar as MuiAppBar, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import React, { useState } from 'react';
import { IMAGE_ASSETS } from "../../Assets/img/getImg";
import { useAuthStatus } from "../../Hooks/Auth/useAuthStatus";
import { useUser } from "../../Hooks/Auth/useUser";
import { useAppDispatch } from "../../Redux/Hooks/hooks.redux";
import { setAuthState } from "../../Redux/Reducer/app.state.reducer";
import { Route } from "../../Routes/routes";
import { ConditionalLink, NestedMenu } from "../Misc/NestedMenu/NestedMenu";
import { MenuItem as MI } from "../Misc/NestedMenu/NestedMenuProps";
import { UIModeToggle } from "../Misc/UIModeToggle";
import { Feedback } from "../Feedback/Feedback";
import { ChatBubbleOutline } from "@mui/icons-material";


interface AppBarProps {
    routes: Route[];
}

export const AppBar: React.FC<AppBarProps> = ({ routes }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [feedbackOpen, setfeedbackOpen] = useState(false);
    const isAuth = useAuthStatus();
    const user = useUser();
    const dispatch = useAppDispatch()
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(setAuthState(
            {
                lastLoggedIn: null,
                user: null,
                isAuth: false,
                boxURL: "",
                org: null,
            }
        ))
        handleClose();
    };

    const MyAvatar : React.FC = () => user ?
      <Avatar sx={{ backgroundColor: "#595959", color: "white"}}>
        {user.firstName[0]}
      </Avatar> : null

    return (
        <React.Fragment>
            <MuiAppBar data-testid='appBar' position="sticky">
                <Toolbar>
                    {isAuth && user && (
                        <React.Fragment>
                            <ConditionalLink to={'/'}>
                                <img style={{ height: '36px', marginRight: '10px' }} src={IMAGE_ASSETS["roper_logo"]} alt="Roper Logo" />
                            </ConditionalLink>
                            <NestedMenu items={(routes.filter(route => route.isOnNav)) as MI[]}></NestedMenu>
                        </React.Fragment>
                    )}
                    <Box sx={{ ml: 'auto' }}>
                        {isAuth && user && (
                            <React.Fragment>
                                <Tooltip title="Submit feedback">
                                    <Button variant="outlined" sx={{ color: 'white' }} onClick={() => setfeedbackOpen(true)}>
                                        <ChatBubbleOutline fontSize={"large"}/>
                                    </Button>
                                </Tooltip>
                                <Button onClick={handleMenu}>
                                    <MyAvatar/>
                                </Button>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <MenuItem disabled>
                                        <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                            <MyAvatar/>
                                            <Typography>{user.firstName} {user.lastName}</Typography>
                                        </Stack>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </React.Fragment>
                        )}
                    </Box>
                    <UIModeToggle />
                </Toolbar>
            </MuiAppBar>
            {feedbackOpen && <Feedback open={feedbackOpen} onClose={setfeedbackOpen} />}
        </React.Fragment>
    );
}
