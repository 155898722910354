import { Button, Stack } from "@mui/material"
 
import { RequiredPrompts } from "./required.prompts"
import { PersonRow } from "./personRow";
import { useFieldArray } from "react-hook-form";
import { newPI } from "../../../Types/SurveyData/Utils/detailed.info.utils";
import { FieldDescription } from "../../Misc/FieldDescription";

export const ProjectLeadsRow: React.FC<{}> = () => {

  var {fields, insert, remove} = useFieldArray({name: "projectLeads"})

  return <>
    <RequiredPrompts req={false} minReq={false} header="Project Lead(s)"></RequiredPrompts>
    <FieldDescription>
      Please list the project lead(s) at each sponsor organization for this poll.
    </FieldDescription>
    <Stack direction={"column"} sx={{ width: "100%" }} spacing={1}>
      <div style={{ maxHeight: "300px", overflowY: 'scroll' }}>
        {fields.map((field, index) =>
          <PersonRow key={field.id} name="projectLeads" index={index} remove={remove} deleteDisabled={false} />
        )}
      </div>
      <Button sx = {{alignSelf: "flex-start"}}  onClick={()=>{insert(fields.length, newPI)}}>
        Add Project Lead
      </Button>
    </Stack>
  </>
}