import { AutoGraph } from "@mui/icons-material";
import { Container, Skeleton, Stack, Typography } from "@mui/material";
import { DataGrid, GridRowsProp, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { useReportingQuery } from "../../../Client/Reporting/survey.reporting.client";
import { useUser } from "../../../Hooks/Auth/useUser";
import { ErrorOverlay } from "../../Misc/ErrorOverlay";
import { surveyCols } from "../../Misc/Grid";
interface SurveyUsagePageProps {
    // none!
}

export const renderErrorOverlay = () => { return <ErrorOverlay message={"No data"}></ErrorOverlay> }

export const SurveyUsagePage: React.FC<SurveyUsagePageProps> = () => {

    const tab = "table"
    const associationId = useUser()!.associationId!
    const [rows, setRows] = useState<GridRowsProp>([]);

    const { isPending, data } = useReportingQuery(associationId)

    useEffect(() => {
        if (data && data.data.data && data.data.data.length > 0) {
            setRows(data.data.data);
        }
    }, [data]);

    return <Container>
        <Stack direction="column" alignItems={"flex-start"} spacing={2}>
            <Stack direction="row" alignItems={"center"} spacing={1}>
                <AutoGraph color="primary" style={{fontSize: "3rem", paddingRight: '1rem'}}/>
                <Typography variant="h1" color="primary" gutterBottom>
                    Survey Usage Data
                </Typography>
            </Stack>

            {isPending && <Skeleton sx={{ width: "100%", height: "100%" }}></Skeleton>}
            {tab === 'table' && data && !isPending &&
                <div style={{ height: "400px", width: "100%" }}>
                    <DataGrid
                        sx={{
                            width: "100%", boxShadow: 2,
                            border: 2,
                            borderColor: 'primary.light',
                            '& .MuiDataGrid-cell:hover': {
                                color: 'primary.main',
                            }
                        }}
                        rows={rows}
                        columns={surveyCols}
                        getRowId={(row)=>{
							return row.archiveId;
						}} 
                        slots={{
                            noRowsOverlay: renderErrorOverlay,
                            toolbar: GridToolbar
                        }}
                        slotProps={{
                            toolbar: {
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                    /></div>}
            
        </Stack>
    </Container>
}