import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { findEndpointById } from "../Endpoints/endpoints";
import { SERVICE_ID } from "../../Constants/ui.constants";
import { useHandleHttpError } from "../../Hooks/misc";
export type ReportingReponse = {
	archiveId:number,
	title:string,
    studyviews:number,
    questionviews:number,
    downloads:number
}
export type ReportingResponseWrapped = {data:{data: ReportingReponse[]}}
export async function fetchReporting(pid:string): Promise<ReportingResponseWrapped>{
    const endpoint = findEndpointById("survey")!
    var url = new URL(endpoint)   
    url.searchParams.append('providerId', pid)
    return axios.get(url.toString(), {
        withCredentials: true,
        headers: {
            S: SERVICE_ID
        }
    })
}

export const useReportingQuery = (pid:string) => {
  const handleHttpError = useHandleHttpError();
  return useQuery({
    queryKey: ['filterReportingData'],
    queryFn: () => fetchReporting(pid),
    throwOnError: handleHttpError,
    staleTime: 24 * 60 * 60 * 1000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  })
}