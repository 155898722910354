import { GridColDef } from "@mui/x-data-grid";

export const studyStatus = {
    PENDING: 'Pending',
    WIP: 'In Progress',
    COMPLETE: 'Complete',
    UNKNOWN: 'Unknown'
};

export const submissionCols: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "archiveID", headerName: "Archive Number", flex: 0.5 },
    { field: "title", headerName: "Title", flex: 1 },
    {
        field: "date", headerName: "Submitted Date", type: 'date', flex: 0.5,
        valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        })
    },
    {
        field: "status", headerName: "Status", type: 'singleSelect', flex: 0.5,
        valueOptions: Object.values(studyStatus)
    },
]

export const reportingCols: GridColDef[] = [
    {
        field: "date", headerName: "Time Period", flex: 1, type: 'date',
        valueFormatter: (params) => new Date(params.value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
        })
    },
    { field: "downloads", headerName: "File Downloads", flex: 1 },
    { field: "studyviews", headerName: "Study Views", flex: 1 },
    { field: "questionviews", headerName: "Question Views", flex: 1 }
]

export const surveyCols: GridColDef[] = [
	{ field: "title", headerName: "Title", flex: 1 },
    { field: "archiveId", headerName: "Archive ID", flex: 1 },
    { field: "downloads", headerName: "File Downloads", flex: 1 },
    { field: "studyviews", headerName: "Study Views", flex: 1 },
    { field: "questionviews", headerName: "Question Views", flex: 1 }
]
