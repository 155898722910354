import axios from 'axios';
import { AuthState } from '../../State/Auth/auth.state';
import { User } from '../../Types/User';
import { getBoxUrl } from '../Box/box.client';
import { findEndpointById } from '../Endpoints/endpoints';
import { getOrg } from '../Org/org.client';
import { SERVICE_ID } from '../../Constants/ui.constants';
export async function auth(username: string, password: string): Promise<AuthState> {
    try {
        const endpoint = findEndpointById("login")!;
        const loginResponse = await axios.post<User>(endpoint, {
            username: username,
            password: password,
        }, {
            withCredentials: true,
            headers: {
                S: SERVICE_ID
            }
        });
        const user = loginResponse.data;
        if (!user.externalId) throw new Error("Malformed user.")
        const boxUrlResponse = await getBoxUrl(user.externalId!);
        const boxUrl = boxUrlResponse.data.data.boxURL;
        const orgId = user.associationId;
        const orgResponse = (await getOrg(orgId)).data
        return {
            isAuth: true,
            lastLoggedIn: (new Date()).toString(),
            user: user,
            boxURL: boxUrl,
            org: orgResponse.data,
        };
    } catch (error) {
        throw (error)
    }
}