import { Container } from "@mui/material";
import { useBoxUrl } from "../../Hooks/Auth/useBoxURL";
import { ErrorOverlay } from "../Misc/ErrorOverlay";
export const BoxUpload: React.FC = () => {
    const boxUrl = useBoxUrl()
    return <Container sx={{
        height: 'calc(100vh - 68px)',
        backgroundColor: '#f4f4f4'
    }}>
        {boxUrl ?
            <iframe
                title="boxURL"
                src={`${boxUrl}`} style={{
                    overflow: 'hidden',
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    height: '100%',
                    border: 'none',
                    width: '100%',
                }} ></iframe>
            :
            <ErrorOverlay message="Your account is not configured for direct file upload, please contact the Roper Center for assistance."></ErrorOverlay>
        }
    </Container >

}