import axios from "axios";
import { findEndpointById } from "../Endpoints/endpoints";
import { SERVICE_ID } from '../../Constants/ui.constants';

export async function uploadFiles(files: FormData, sid: string) {
    let endpoint = findEndpointById("files")!
    let url = new URL(endpoint);
    url.searchParams.append('sid', sid)
    return await axios.post(url.toString(), files, {
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data',
            S: SERVICE_ID
        }
    })
}
