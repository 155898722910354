import { ReactNode, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuthStatus } from "../../Hooks/Auth/useAuthStatus";
import { useNetworkStatus } from "../../Hooks/Network/useNetworkState";
import { Route as RouteType } from "../../Routes/routes";
import { ErrorPage } from "../404/error.page";
import { Page } from "../Misc/Page";
import { AppBar } from "../Navigation/app.bar";
import { ROOT_URL } from "../../Constants/ui.constants";

interface RouterProps {
    routes: RouteType[],
    children: ReactNode
}
/**
 * Unwraps nested routes into a 1-d base level route array.
 *
 * @param routes list of routes
 * @param parentPath path prefixed to all routes
 * @returns 1d base-level route array.
 */
export function flattenRoutes(routes: RouteType[], parentPath: string = ''): RouteType[] {
    let flattenedRoutes: RouteType[] = [];
    routes.forEach(route => {
        const fullPath = parentPath + route.id;
        flattenedRoutes.push({
            ...route,
            id: fullPath
        });
        if (route.children && route.children.length > 0) {
            flattenedRoutes = flattenedRoutes.concat(flattenRoutes(route.children, fullPath));
        }
    });

    return flattenedRoutes;
}

/**
 * Higher Order Router Component that renders individual base-level routes
 * as defined in [routes].
 * This component will also watch for changes to the authStatus and networkStatus 
 * and redirect accordingly.
 * No [useNavigate] required!
 */
export const AppRouter: React.FC<RouterProps> = ({ routes, children }: RouterProps) => {
    const [unwrapped, setUnwrapped] = useState<RouteType[]>([]);
    const authStatus = useAuthStatus();
    const networkStatus = useNetworkStatus()
    const UNAUTH_ROUTE = "auth/login"        // where you go if you are unauthenticated and visit a page that needs auth
    const AUTH_ROUTE = '/'                   // where you go if you visit the auth page and you are authenticated
    const ERROR_ROUTE = '404'
    useEffect(() => {
        if (routes) {
            setUnwrapped(flattenRoutes([...routes]))
        }
    }, [routes])

    return useMemo(() => {
        const getRedirectAuthRoute = (route: RouteType, index: number) => {
            return (
              <Route
                path={route.id}
                key={index}
                element={
                    networkStatus ? <Navigate to={`/${ERROR_ROUTE}`} replace />
                      : !authStatus ? route.component ? <Page> <route.component /></Page> : null
                        : <Navigate to={AUTH_ROUTE} replace />
                }
              />
            );
        };

        const getRedirectRoute = (route: RouteType, index: number) => {
            return (
              <Route
                path={route.id}
                key={index}
                element={
                    networkStatus ? <Navigate to={`/${ERROR_ROUTE}`} replace />
                      : authStatus ? route.component ? <Page> <route.component /></Page> : null
                        : <Navigate to={`/${UNAUTH_ROUTE}`} replace />
                }
              />
            );
        };

        const getRedirectFunction = (route: RouteType) =>
          route.id.startsWith('/auth/') ? getRedirectAuthRoute : getRedirectRoute;

        if (!(unwrapped)) return <></>
        return (<BrowserRouter basename={ROOT_URL}>
            <AppBar routes={unwrapped.filter(route => route.isOnNav && route.isBase)} />
            <Routes>
                <Route path="/404" element={<ErrorPage></ErrorPage>}></Route>
                {unwrapped.map((route: RouteType, index) => (
                    getRedirectFunction(route)(route, index)
                ))}
                {children}
            </Routes>
        </BrowserRouter>)
    }, [authStatus, networkStatus, unwrapped, children])
}