import { Paper, Stack, Typography } from "@mui/material";
import { IMAGE_ASSETS } from "../../Assets/img/getImg";
interface ErrorOverlayProps{
    message: string,
    children?: React.ReactNode
}

export const ErrorOverlay:React.FC<ErrorOverlayProps> = ({message,children}:ErrorOverlayProps) => {
    return <Paper elevation={0} sx={{alignItems:"center", display:'flex', justifyContent:'center', height:'100%'}}>
        <Stack direction="column" spacing={2} justifyContent={"center"} alignItems={"center"}>
            <img style={{width:"200px"}} alt="error-img"  src={IMAGE_ASSETS["error"]}></img>
            <Typography variant="littleLabel">{message}</Typography>
            {children}
        </Stack>
    </Paper>
}